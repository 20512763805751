import { createSlice } from '@reduxjs/toolkit'

const stateSlice = createSlice({
	name: 'chat/state',
	initialState: {
	},
	reducers: {
	},
})

export default stateSlice.reducer
